export const USER_ATTRIBUTES = {
  first_name: {
    name: "First Name",
    value: "<%= userInfo.firstName %>",
    sample: "John",
  },
  last_name: {
    name: "Last Name",
    value: "<%= userInfo.lastName %>",
    sample: "Doe",
  },
  role: {
    name: "User Role",
    value: "<%= userInfo.userRole %>",
    sample: "User",
  },
  business_company_name: {
    name: "Business Company Name",
    value: "<%= business_company_name %>",
    sample: "Wealthx",
  },
};
