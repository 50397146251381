export const tooltipsBaseOption = {
    mode: 'nearest',
    intersect: false,
    backgroundColor: '#fff',
    titleFontFamily: 'ProximaNovaRegular',
    titleFontStyle: 'normal',
    titleFontSize: 14,
    titleFontColor: '#000',
    bodyFontFamily: 'ProximaNovaRegular',
    bodyFontStyle: 'bold',
    bodyFontColor: '#000',
    bodyFontSize: 13,
    displayColors: false,
    borderColor: '#000',
    borderWidth: 1,
} as const;
