import { useMemo } from "react";
import { TContactAlertSeverity } from "./contact-alert-severity-indicator";
import { transformToCamelCase } from "../../utils/stuff";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import classNames from "classnames";

export type AlertMatchingBySeveritySummaryItemProps = {
  severity: TContactAlertSeverity | "HEALTHY";
  total: number;
  totalLastMonth: number;
};

export const AlertMatchingBySeveritySummaryItem: React.FC<AlertMatchingBySeveritySummaryItemProps> = ({
  severity,
  total,
  totalLastMonth,
}) => {
  const colorVar = useMemo(() => {
    return `var(--${transformToCamelCase(severity)}AlertColor)`;
  }, [severity]);
  const title = useMemo(() => {
    switch (severity) {
      case "NEED_ACTION":
        return "Need Action";
      case "WATCH":
        return "Watch";
      case "HEALTHY":
        return "Healthy";
      default:
        return null;
    }
  }, [severity]);
  const diff = useMemo(() => {
    return total - totalLastMonth;
  }, [total, totalLastMonth]);

  const diffInPercent = useMemo(() => {
    return totalLastMonth === 0
      ? 0
      : parseFloat(((diff / totalLastMonth) * 100).toFixed(2));
  }, [total, totalLastMonth]);

  const trendingColor = useMemo(() => {
    if (diff === 0) {
      return null;
    }
    // green for up-trend, red for down-trend
    return diff > 0 ? "#39FD99" : "#F15D5D";
  }, [diff]);

  const trendingIcon = useMemo(() => {
    if (diff === 0) {
      return null;
    }
    return diff > 0 ? (
      <TrendingUpIcon fontSize="inherit" htmlColor={trendingColor} />
    ) : (
      <TrendingDownIcon fontSize="inherit" htmlColor={trendingColor} />
    );
  }, [diff, trendingColor]);

  return (
    <div className="alert-matching-by-severity-container">
      <div className="title">{title}</div>
      <div
        style={{
          borderLeftColor: colorVar,
        }}
        className="info-with-color-indicator"
      >
        <div>{total}</div>
        <div className="performance-to-last-month">
          <div className="performance-to-last-month__raw">
            {diff >= 0 ? "+" : ""}
            {diff} This month
          </div>
          {totalLastMonth > 0 && (
            <div className={classNames("performance-to-last-month__percent")}>
              <div className="trending-icon">{trendingIcon}</div>
              <div
                style={{
                  color: trendingColor,
                }}
                className="text"
              >
                {diffInPercent}%
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
