import { CircularProgress } from "@mui/material";
interface LoadingChartBackdropProps {
  isError: boolean;
  isLoading: boolean;
}
export const LoadingChartBackdrop: React.FC<LoadingChartBackdropProps> = ({
  isError,
  isLoading,
}) => {
  return (
    <>
      {isError ? (
        <span>
          Oops! Something went wrong. Chart data has been lost. Please try again
          after a few minutes.
        </span>
      ) : isLoading ? (
        <>
          <CircularProgress color="inherit" className="connecting-spinner" />
          <span>Loading chart data. This might take several minutes.</span>
        </>
      ) : null}
    </>
  );
};
