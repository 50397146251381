import { useMemo } from "react";
import { transformToCamelCase } from "../../utils/stuff";

export type TContactAlertSeverity = "NEED_ACTION" | "WATCH" | "INSIGHT";

interface ContactAlertSeverityIndicatorProps {
  severityCode: TContactAlertSeverity;
  widthInPx?: number;
  heightInPx?: number;
  borderRadiusInPx?: number;
}

const ContactAlertSeverityIndicator: React.FC<ContactAlertSeverityIndicatorProps> = ({
  severityCode,
  widthInPx = 3,
  heightInPx = 28,
  borderRadiusInPx = 10,
}) => {
  const colorVar = useMemo(() => {
    return `var(--${transformToCamelCase(severityCode)}AlertColor)`;
  }, [severityCode]);

  return (
    <div
      style={{
        backgroundColor: colorVar,
        borderRadius: `${borderRadiusInPx}px`,
        width: `${widthInPx}px`,
        height: `${heightInPx}px`,
      }}
      className={`contact-alert-severity-indicator`}
    />
  );
};
export default ContactAlertSeverityIndicator;
