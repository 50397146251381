import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)({
  textTransform: "none",
});

const ImportContactButton = ({ onClick = () => {} }) => {
  return (
    <div className="contact__import-btn">
      <StyledButton
        variant="text"
        component="label"
        role={undefined}
        tabIndex={-1}
        endIcon={<FileDownloadOutlinedIcon color="success" />}
      >
        Import Contacts
        <VisuallyHiddenInput type="file" onChange={onClick} accept=".csv" />
      </StyledButton>
    </div>
  );
};

ImportContactButton.propTypes = {
  onClick: PropTypes.func,
};

export default ImportContactButton;
