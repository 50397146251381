import { Divider } from "@mui/material";
import {
  AlertMatchingBySeveritySummaryItem,
  AlertMatchingBySeveritySummaryItemProps,
} from "./alert-matching-by-severity-summary-item";
import {
  AlertMatchingsChart,
  AlertMatchingsChartProps,
} from "./alert-matchings-chart";

// @ts-ignore
type AlertMatchingHistorySectionProps = {
  alertMatchingsChartData: AlertMatchingsChartProps;
  alertMatchingBySeveritySummaryItems: AlertMatchingBySeveritySummaryItemProps[];
};

export const AlertMatchingHistorySection: React.FC<AlertMatchingHistorySectionProps> = ({
  alertMatchingsChartData,
  alertMatchingBySeveritySummaryItems,
}) => {
  return (
    <div className="alert-matching-history-section-container">
      <div className="alert-matching-history-section">
        <div className="alert-matching-history-section__chart">
          <AlertMatchingsChart {...alertMatchingsChartData} />
        </div>
        {alertMatchingBySeveritySummaryItems?.length && (
          <div className="alert-matching-history-section__summary">
            {alertMatchingBySeveritySummaryItems.map((item, index) => (
              <>
                <AlertMatchingBySeveritySummaryItem
                  key={`alert-matching-by-severity-item-${index}`}
                  {...item}
                />
                <Divider
                  className="alert-matching-history-section__summary-divider"
                  variant="middle"
                />
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
