import ScrollContainer from "react-indiana-drag-scroll";
import { IContactAlert, ContactAlertItem } from "./contact-alert-item";
import AddContactAlertButton from "./add-contact-alert";
import { useContext, useEffect } from "react";
import { TContactContext } from "../../context/contact-context.type";
import ContactContext from "../../context/contact-context";
import { queryValue } from "./contact-alert-dialog";

interface ContactAlertListCarouselProps {
  totalAlertHit: number;
  totalAlertLastMonth: number;
  totalContact: number;
  totalContactLastMonth: number;
  alertList: IContactAlert[];
}

const ContactAlertListCarousel: React.FC<ContactAlertListCarouselProps> = ({
  alertList,
  totalAlertHit,
  totalAlertLastMonth,
  totalContact,
  totalContactLastMonth,
}) => {
  const {
    handleAlertDialog,
    selectedContactAlert,
    setSelectedContactAlert,
    isAlertTotalItemSelected: isTotalItemSelected,
    setIsAlertTotalItemSelected: setIsTotalItemSelected,
    handleSelectContactAlert,
    setAlertData,
  } = useContext<TContactContext>(ContactContext);

  const handleAddAlert = () => {
    setAlertData(null);
    setSelectedContactAlert("");
    setIsTotalItemSelected(false);
    handleAlertDialog();
  };

  return (
    <ScrollContainer horizontal className="contact-alert__carousel-container">
      <div className="contact-alert__carousel-container__alert-list">
        <ContactAlertItem
          key={`contact_alert_all_contact`}
          isTotalAlert={true}
          total={totalContact}
          totalLastMonth={totalContactLastMonth}
          onClick={handleSelectContactAlert}
          isSelected={isTotalItemSelected}
          totalAlertText="All contacts"
        ></ContactAlertItem>
        <ContactAlertItem
          key={`contact_alert_all`}
          isTotalAlert={true}
          total={totalAlertHit}
          totalLastMonth={totalAlertLastMonth}
          onClick={handleSelectContactAlert}
          isSelected={isTotalItemSelected}
        ></ContactAlertItem>
        {alertList?.length > 0 &&
          alertList.map((alert) => (
            <ContactAlertItem
              key={`contact_alert_${alert.id}`}
              alert={alert}
              isSelected={selectedContactAlert === alert.id}
              onClick={handleSelectContactAlert}
            />
          ))}
        <AddContactAlertButton
          key={"contact_alert_add_btn"}
          onClick={handleAddAlert}
        />
      </div>
    </ScrollContainer>
  );
};

export default ContactAlertListCarousel;
