import { useContext, useMemo, useState } from "react";
import {
  formatNumberWithText,
  formatNumber,
  formatNumberWithPercent,
} from "../../utils/numberFormater";
import ContactAlertSeverityIndicator, {
  TContactAlertSeverity,
} from "./contact-alert-severity-indicator";
import classNames from "classnames";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { TContactContext } from "../../context/contact-context.type";
import ContactContext from "../../context/contact-context";
import { useSnackbar } from "notistack";
import { useDeleteAlert } from "../../hooks/contact.hook";
import { UseMutationResult, useQueryClient } from "react-query";
import { useMyInfo } from "../../hooks/user.hooks";
export enum AlertSharingType {
  PRIVATE = "PRIVATE",
  COMPANY = "COMPANY",
}

export interface IContactAlert {
  id: string;
  name: string;
  severity: number;
  sharingType?: AlertSharingType;
  severityCode: TContactAlertSeverity;
  filterSegment: Record<string, any>;
  total: number;
  totalLastMonth: number;
  createdBy?: string;
}

interface TotalAlertItemProps {
  total?: number;
  totalLastMonth?: number;
}

interface ContactAlertItemProps {
  alert?: IContactAlert;
}

type ContactAlertItemCombinedProps = {
  isTotalAlert?: boolean;
  onClick?: (isTotalItem: boolean, alert?: IContactAlert) => void;
  isSelected?: boolean;
  totalAlertText?: string;
} & ContactAlertItemProps &
  TotalAlertItemProps;

export const ContactAlertItem: React.FC<ContactAlertItemCombinedProps> = ({
  alert,
  isTotalAlert = false,
  totalLastMonth,
  total,
  isSelected = false,
  onClick,
  totalAlertText = "All alerts",
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: myInfo } = useMyInfo();
  // listing UI
  const _total = useMemo(() => {
    if (isTotalAlert) {
      return total || 0;
    }
    return alert.total || 0;
  }, [isTotalAlert, alert, total]);
  const _totalLastMonth = useMemo(() => {
    if (isTotalAlert) {
      return totalLastMonth || 0;
    }
    return alert.totalLastMonth || 0;
  }, [isTotalAlert, alert, totalLastMonth]);

  const formattedTotal = useMemo(() => {
    return formatNumberWithText(_total);
  }, [_total]);

  const performanceToLastMonth = useMemo<string>(() => {
    if (_totalLastMonth === 0 || _total === 0) {
      return null;
    }
    const percentageChange =
      ((_total - _totalLastMonth) / _totalLastMonth) * 100;
    return `${percentageChange > 0 ? "+" : ""}${formatNumberWithPercent(
      formatNumber(percentageChange)
    )}`;
  }, [_total, _totalLastMonth]);

  // handle select alert
  const handleSelectAlert = () => {
    onClick(isTotalAlert, alert);
  };

  const isOwner = useMemo(() => {
    return myInfo?.id === alert?.createdBy;
  }, [myInfo, alert]);

  // handle menu
  const [buttonElement, setButtonElement] = useState(null);
  const open = useMemo(() => Boolean(buttonElement), [buttonElement]);
  const handleMenuClick = (e) => {
    e.stopPropagation();
    setButtonElement(e.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setButtonElement(null);
  };

  // handle edit alert
  const { handleAlertDialog, setAlertData } = useContext<TContactContext>(
    ContactContext
  );
  const handleEditAlert = (e) => {
    e.stopPropagation();
    setAlertData(alert);
    handleAlertDialog();
    handleClose(e);
  };

  // handle delete alert
  const deleteAlert: UseMutationResult<
    any,
    unknown,
    string,
    unknown
  > = useDeleteAlert();
  const handleDeleteAlert = (e) => {
    e.stopPropagation();
    if (!alert?.id) return;
    deleteAlert.mutate(alert.id, {
      onSuccess: () => {
        queryClient.invalidateQueries("alertList");
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar("Failed to delete alert", {
          variant: "error",
        });
      },
    });
    handleClose(e);
  };
  return (
    <>
      <div
        className={classNames("carousel-item-container", {
          "total-selected": isSelected && isTotalAlert,
          [`selected-${alert?.severityCode?.toLowerCase()}`]:
            !isTotalAlert && alert && isSelected,
        })}
        onClick={handleSelectAlert}
      >
        <div className="alert__total">
          {!isTotalAlert && (
            <ContactAlertSeverityIndicator severityCode={alert.severityCode} />
          )}
          <div>{formattedTotal}</div>
        </div>
        <div className="alert__name alert__inner">
          {isTotalAlert ? totalAlertText : alert.name}
        </div>
        {performanceToLastMonth ? (
          <div className="alert__statistics alert__inner">
            <div>{performanceToLastMonth}</div>
            <div className="alert__statistics-time-period">This Month</div>
          </div>
        ) : (
          <div className="alert__statistics alert__inner">
            <div>N/A</div>
          </div>
        )}
        {/* edit and delete section */}
        {!isTotalAlert && isOwner ? (
          <div className="carousel-item-container__menu">
            <IconButton
              aria-label="alert item menu"
              onClick={handleMenuClick}
              className="carousel-item-container__menu-icon"
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="contact-alert-menu"
              anchorEl={buttonElement}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "contact-alert-button",
              }}
            >
              <MenuItem onClick={handleEditAlert}>Edit alert</MenuItem>
              <MenuItem onClick={handleDeleteAlert}>Delete alert</MenuItem>
            </Menu>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
