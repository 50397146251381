export const ALERT_CHART_MOCK_DATA = {
  general: [
    {
      period: "2023-11-01",
      total: 270,
    },
    {
      period: "2023-12-01",
      total: 350,
    },
    {
      period: "2024-01-01",
      total: 290,
    },
    {
      period: "2024-02-01",
      total: 450,
    },
    {
      period: "2024-03-01",
      total: 400,
    },
    {
      period: "2024-04-01",
      total: 550,
    },
    {
      period: "2024-05-01",
      total: 500,
    },
    {
      period: "2024-06-01",
      total: 520,
    },
    {
      period: "2024-07-01",
      total: 525,
    },
    {
      period: "2024-08-01",
      total: 616,
    },
    {
      period: "2024-09-01",
      total: 507,
    },
    {
      period: "2024-10-01",
      total: 630,
    },
    {
      period: "2024-11-01",
      total: 700,
    },
  ],
  healthy: [
    {
      period: "2023-11-01",
      total: 250,
    },
    {
      period: "2023-12-01",
      total: 262,
    },
    {
      period: "2024-01-01",
      total: 248,
    },
    {
      period: "2024-02-01",
      total: 300,
    },
    {
      period: "2024-03-01",
      total: 325,
    },
    {
      period: "2024-04-01",
      total: 330,
    },
    {
      period: "2024-05-01",
      total: 334,
    },
    {
      period: "2024-06-01",
      total: 337,
    },
    {
      period: "2024-07-01",
      total: 260,
    },
    {
      period: "2024-08-01",
      total: 328,
    },
    {
      period: "2024-09-01",
      total: 290,
    },
    {
      period: "2024-10-01",
      total: 425,
    },
    {
      period: "2024-11-01",
      total: 500,
    },
  ],
  onWatch: [
    {
      period: "2023-11-01",
      total: 5,
    },
    {
      period: "2023-12-01",
      total: 50,
    },
    {
      period: "2024-01-01",
      total: 7,
    },
    {
      period: "2024-02-01",
      total: 75,
    },
    {
      period: "2024-03-01",
      total: 66,
    },
    {
      period: "2024-04-01",
      total: 120,
    },
    {
      period: "2024-05-01",
      total: 112,
    },
    {
      period: "2024-06-01",
      total: 95,
    },
    {
      period: "2024-07-01",
      total: 88,
    },
    {
      period: "2024-08-01",
      total: 130,
    },
    {
      period: "2024-09-01",
      total: 20,
    },
    {
      period: "2024-10-01",
      total: 34,
    },
    {
      period: "2024-11-01",
      total: 42,
    },
  ],
  needAction: [
    {
      period: "2023-11-01",
      total: 200,
    },
    {
      period: "2023-12-01",
      total: 210,
    },
    {
      period: "2024-01-01",
      total: 195,
    },
    {
      period: "2024-02-01",
      total: 222,
    },
    {
      period: "2024-03-01",
      total: 53,
    },
    {
      period: "2024-04-01",
      total: 110,
    },
    {
      period: "2024-05-01",
      total: 105,
    },
    {
      period: "2024-06-01",
      total: 92,
    },
    {
      period: "2024-07-01",
      total: 88,
    },
    {
      period: "2024-08-01",
      total: 85,
    },
    {
      period: "2024-09-01",
      total: 119,
    },
    {
      period: "2024-10-01",
      total: 102,
    },
    {
      period: "2024-11-01",
      total: 140,
    },
  ],
};
