import { useCallback, useEffect, useMemo, useRef } from "react";
import { tooltipsBaseOption } from "../../utils/chartUtils";
import { TContactAlertSeverity } from "./contact-alert-severity-indicator";
import { Line } from "react-chartjs-2";
import classNames from "classnames";
import { LoadingChartBackdrop } from "../../commons/loading-chart-backdrop";
interface AlertMatchingHistoryItem {
  period: string;
  total: number;
}

interface AlertMatchingHistories {
  general: AlertMatchingHistoryItem[];
  needAction: AlertMatchingHistoryItem[];
  onWatch: AlertMatchingHistoryItem[];
  healthy: AlertMatchingHistoryItem[];
}

export type AlertMatchingsChartSeverityView =
  | TContactAlertSeverity
  | "HEALTHY"
  | "COMBINED";

export type AlertMatchingsChartProps = {
  data: AlertMatchingHistories;
  isLoading: boolean;
  isError: boolean;
  severityView?: AlertMatchingsChartSeverityView;
};

const chartOption = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        type: "time",
        time: {
          unit: "month",
        },
        distribution: "linear",
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: false,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          maxTicksLimit: 5,
        },
      },
    ],
  },
  maintainAspectRatio: false,
  responsive: true,
  tooltips: {
    ...tooltipsBaseOption,
    mode: "nearest",
  },
  hover: {
    mode: "nearest",
    intersect: false,
  },
};

const buildDataSet = (
  data: AlertMatchingHistoryItem[],
  severity?: TContactAlertSeverity | "HEALTHY"
) => {
  let label = "All contact";
  let chartColor = "#131c24";
  let secondaryChartColor = "#131c24";
  switch (severity) {
    case "NEED_ACTION":
      label = "Need Action";
      chartColor = "rgba(241, 93, 93, 1)";
      secondaryChartColor = "rgba(241, 93, 93, 0.3)";
      break;
    case "WATCH":
      label = "Keep an eye on it";
      chartColor = "rgba(255, 245, 0, 1)";
      secondaryChartColor = "rgba(255, 245, 0, 0.3)";
      break;
    case "HEALTHY":
      label = "Healthy";
      chartColor = "rgba(41, 242, 121, 1)";
      secondaryChartColor = "rgba(41, 242, 121, 0.3)";
      break;
    default:
      break;
  }
  const points = data.map((item) => {
    return {
      x: item.period,
      y: item.total,
    };
  });
  return {
    label,
    fill: false,
    backgroundColor: secondaryChartColor,
    borderColor: chartColor,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: chartColor,
    pointBackgroundColor: chartColor,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: chartColor,
    pointHoverBorderColor: secondaryChartColor,
    pointHoverBorderWidth: 8,
    pointRadius: 0,
    borderWidth: 2,
    pointHitRadius: 3,
    data: points,
    pointStyle: "line",
  };
};

export const AlertMatchingsChart: React.FC<AlertMatchingsChartProps> = ({
  data,
  isLoading,
  isError,
  severityView,
}) => {
  const chartRef = useRef(null);

  const chartData = useMemo(() => {
    if (!data) return [];
    const general = buildDataSet(data.general);
    const healthy = buildDataSet(data.healthy, "HEALTHY");
    const watch = buildDataSet(data.onWatch, "WATCH");
    const needAction = buildDataSet(data.needAction, "NEED_ACTION");
    return {
      datasets: [general, healthy, watch, needAction],
    };
  }, [data]);

  const chartLegendConfig = useMemo(() => {
    return {
      display: true,
      position: "bottom",
      fullWidth: true,
      reverse: false,
      labels: {
        padding: 40,
        usePointStyle: true,
        boxWidth: 40,
        generateLabels: (chart) => {
          const { datasets } = chart.data;
          return datasets.map((dataset, index) => {
            const hidden = dataset._meta[0]?.hidden;
            return {
              text: dataset.label,
              fillStyle: dataset.borderColor,
              hidden,
              lineCap: "butt",
              lineDash: [],
              lineDashOffset: 0,
              lineJoin: "miter",
              lineWidth: 6,
              strokeStyle: dataset.borderColor,
              pointStyle: "line",
              rotation: 0,
              datasetIndex: index,
            };
          });
        },
      },
    };
  }, []);
  const datasetIndexToHide = useMemo<Set<number>>(() => {
    switch (severityView) {
      case "HEALTHY":
        return new Set([0, 2, 3]);
      case "WATCH":
        return new Set([0, 1, 3]);
      case "NEED_ACTION":
        return new Set([0, 1, 2]);
      default:
        return new Set();
    }
  }, [severityView]);
  // on severity view change
  useEffect(() => {
    const ci = chartRef.current.chartInstance;
    ci?.data?.datasets?.forEach((dataset, index) => {
      const meta = dataset?._meta[0];
      if (meta) {
        if (datasetIndexToHide.has(index)) {
          meta.hidden = true;
          meta.dataset.hidden = meta.hidden;
        } else {
          meta.hidden = false;
          meta.dataset.hidden = meta.hidden;
        }
      }
    });
    ci.update();
  }, [severityView, chartRef]);

  return (
    <div className="line-chart-container">
      <div className="line-chart-size">
        {!isLoading && !isError && (
          <Line
            ref={chartRef}
            data={chartData}
            options={chartOption}
            legend={chartLegendConfig}
          />
        )}
        <div
          className={classNames("loading-chart-indicator", {
            "d-none": !isLoading && !isError,
          })}
        >
          <LoadingChartBackdrop isLoading={isLoading} isError={isError} />
        </div>
      </div>
    </div>
  );
};
